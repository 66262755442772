<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="mysuggestion">
        <p class="mysuggestion_tit">选择问题类型</p>
        <ul class="mysuggestion_xuan">
            <li v-for='(i,index) in list' @click="dangqian=index" :key="index"><img :src="dangqian==index?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}</li>
        </ul>
        <p class="mysuggestion_yijian_tit">({{wenti.length}}/500)</p>
        <textarea class="mysuggestion_yijian" type="text" maxlength="500" v-model="wenti" placeholder="描述您遇到的问题（500字以内）"></textarea>
        <p class="shangchuan_tit">上传照片（{{img64.length}}/9）</p>
        <div class="shangchuan_tupian_list">
            <div class="dakai_tupian" v-if="img64.length!=0">
                <p  v-for="(i,index) in img64" :key="index" @click="dianji_tupian(index)">
                    <img :src="img64[index]" alt="">
                    <i class="el-icon-error" @click.stop @click="dianji_shanchu(index)"></i>
                </p>
            </div>
            <p class="dianji_shangchuan_tu" v-if="img64.length!=9">
                <img src="../../assets/increase.png" alt>
                <input type="file" accept='image/*' @change="getImgBase()">
            </p>
        </div>
        <div v-if="fangda" class="da_img" @click="fangda=false">
            <img :src="img64[xuanzhong]">
        </div>
        <p class="mysuggestion_tit">选择企业</p>
        <ul class="mysuggestion_xuan">
            <li v-for='(i,index) in qiye_list' @click="ent_index=index" :key="i.ent_id"><img :src="ent_index==index?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')" alt="">{{i.ent_name}}</li>
        </ul>
        <p class="mysuggestion_phone">联系手机号: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="text" v-model="phone"></p>
        <p class="mysuggestion_btn" @click="dianji_queding">确定</p>
    </div>
</template>

<script>
import { query_user_ent_list, add_my_advise } from '../../api/api.js'
export default {
  name: 'mysuggestion',
  data () {
    return {
      list: ['新需求', '界面展示速度优化', '核心功能缺失', '界面无法交互', '图形逻辑错误', '数值计算错误', '其他'],
      dangqian: '0',
      wenti: '',
      img64: [],
      lei: [],
      xin: [],
      fangda: false,
      xuanzhong: '0',
      phone: '',
      ent_index: '0',
      qiye_list: []
    }
  },
  mounted () {

  },
  created () {
    this.phone = this.$jichuxinxi().mobile,
    this.jichu()
  },
  methods: {
    jichu () {
      query_user_ent_list({
        data: {
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10080) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          this.qiye_list = date
        } else if (res.data.code == 10081) {
        }
      })
    },
    // 打开相册
    getImgBase () {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      _this.lei.push(file.name.substring(file.name.indexOf('.'), file.name.length))
      // 转base64
      reader.onload = function (e) {
        _this.img64.push(e.target.result)
        _this.xin.push(e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        ))
      }
      reader.readAsDataURL(file)
    },
    // 点击图片放大
    dianji_tupian (index) {
      this.fangda = true
      this.xuanzhong = index
    },
    // 点击图片删除按钮
    dianji_shanchu (index) {
      this.xin.splice(index, 1)
      this.lei.splice(index, 1)
      this.img64.splice(index, 1)
    },
    // 点击确定
    dianji_queding () {
      if (this.qiye_list.length != 0) {
        add_my_advise({
          data: {
            user_id: this.$jichuxinxi().user_id,
            mobile: this.phone,
            adv_txt: this.wenti.length != 0 ? this.wenti : null,
            adv_data: this.xin.length != 0 ? this.xin.join(' ') : null,
            adv_data_type: this.lei.length != 0 ? this.lei.join(' ') : null,
            adv_type: Number(this.dangqian) + 1 + '',
            ent_id: this.qiye_list[this.ent_index].ent_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10071) {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.push('/personset')
          } else if (res.data.code == 10072) {
            this.$message({
              message: '提交失败',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '您没有当前企业',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './geren.scss';
</style>
